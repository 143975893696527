import React, { useCallback, useEffect } from "react";

import MapMarker from "../../../models/MapMarker";
import CustomButton from "../../ui/CustomButton";
import { openNavigate } from "../../../helpers/navigation";
import { getAddressFormat, getServiceIcon } from "../../../helpers/formatters";
import useStore from "../../../hooks-store/store";
import { Button, TButtonSize, Tooltip } from "@velocity/ui";
import MapDrawer from "./MapDrawer";
import { localization } from "../../../localization/localizedStrings";
import { EyeopenIcon, LocationIcon } from "@velocity/icons/system";
import { Partner } from "../../../api/Client";

interface MapInfoWindowProps {
  marker: MapMarker;
}

const handleOnNavigationClick = (marker: MapMarker) => {
  if (marker?.partnerInfo?.latitude && marker.partnerInfo?.longitude)
    openNavigate(marker.partnerInfo?.latitude, marker.partnerInfo?.longitude);
};

const MapInfoWindow = ({ marker }: MapInfoWindowProps) => {
  const [openDrawerStore, setOpenDrawerStore] = useStore<boolean>(
    "openDrawer",
    false
  );
  const [partnerStore, setPartnerStore] = useStore<Partner | null>(
    "partner",
    null
  );

  const handleOnMoreInfoClick = (marker: MapMarker) => {
    setOpenDrawerStore(true);
    setPartnerStore(marker.partnerInfo);
  };

  return (
    <div>
      {/* this input is for focus setting */}
      <input autoFocus style={{ visibility: "hidden", height: "0px" }} />
      <div className="map-info-window-content">
        <strong style={{ padding: "0.25rem" }}>
          {marker?.partnerInfo?.name}
        </strong>
        <div style={{ padding: "0.25rem" }}>
          {getAddressFormat(marker?.partnerInfo)}
        </div>
        <div className="map-info-window-additional-data">
          <div style={{ padding: "0.25rem" }} className="mb-1 secondary-link">
            {marker?.partnerInfo?.distance
              ? Math.round(marker?.partnerInfo?.distance / 1000)
              : ""}{" "}
            km
          </div>
          <div style={{ padding: "0.25rem" }}>
            {localization.contactGsm}: &nbsp;
            <strong>
              <a href={`tel:${marker?.partnerInfo?.gsm}`}>
                {marker?.partnerInfo?.gsm}
              </a>
            </strong>
          </div>
          <div style={{ padding: "0.25rem" }}>
            {localization.contactEmail}:&nbsp;
            <strong>
              <a href={`mailTo:${marker?.partnerInfo?.email}`}>
                {marker?.partnerInfo?.email}
              </a>
            </strong>
          </div>
          <div style={{ padding: "0.25rem" }}>
            {localization.websSite}:&nbsp;
            <strong>
              <a href={`${marker?.partnerInfo?.website}`} target="_blank">
                {marker?.partnerInfo?.website}
              </a>
            </strong>
          </div>
          <div style={{ padding: "0.25rem" }}>
            {localization.openingHours}:&nbsp;
            <strong>{marker?.partnerInfo?.openingHours}</strong>
          </div>
          {/* <div className="mt-1 mb-1">
            <strong>{localization.filterTitle}</strong>
            <div>
              {marker?.partnerInfo?.servicesCollection?.map((service) => (
                <>
                  <img
                    alt={service?.serviceType?.toString()}
                    src={getServiceIcon(service?.serviceType)}
                  />
                </>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <div className="mb-05">
        <CustomButton
          text={localization.navigate}
          fullWidth
          size={"s" as TButtonSize}
          onClick={() => handleOnNavigationClick(marker)}
          variant="primary-filled"
          startIcon={LocationIcon}
        />
      </div>
      <div style={{ marginTop: "0.15rem" }}>
        <Button
          variant="primary-outlined"
          fullWidth
          size={"s" as TButtonSize}
          onClick={() => handleOnMoreInfoClick(marker)}
          StartIcon={EyeopenIcon}
        >
          {localization.moreInformations}
        </Button>
      </div>
      <MapDrawer />
    </div>
  );
};

export default MapInfoWindow;
