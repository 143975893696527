import { Partner } from "../api/Client";
import MapMarker from "../models/MapMarker";
import { MouseEvent, ReactNode } from "react";
import others from "../assets/pictograms/ostatni.png";
import pickup from "../assets/pictograms/odvoz.png";
import airCondition from "../assets/pictograms/klimatizace.png";
import car from "../assets/pictograms/nahradni_vozidlo.png";
import wash from "../assets/pictograms/myti.png";
import { localization } from "../localization/localizedStrings";

export const getMarkerFormat = (
  data: Partner[] | null | undefined,
  onMoreInfoClick: (
    event: MouseEvent<HTMLButtonElement>,
    data: Partner
  ) => void,
  onNavigationClick: (
    event: MouseEvent<HTMLButtonElement>,
    data: Partner
  ) => void
): MapMarker[] => {
  return (data?.map((item: Partner) => ({
    partnerInfo: item,
    onMoreInfoClick,
    onNavigationClick,
  })) || []) as MapMarker[];
};

export const getAddressFormat = (data: Partner | null): string | null => {
  return (
    data &&
    (data.city ?? "") + ", " + (data.street ?? "") + ", " + (data.zip ?? "")
  );
};

export const getServiceIcon = (service: number | undefined): string => {
  switch (service) {
    case 1:
      return airCondition;
    case 2:
      return wash;
    case 3:
      return car;
    case 4:
      return pickup;
    default:
      return others;
  }
};

export const getServiceTitle = (service: number | undefined): string => {
  switch (service) {
    case 1:
      return localization.aircondition;
    case 2:
      return localization.wash;
    case 3:
      return localization.otherCar;
    case 4:
      return localization.pickup;
    default:
      return localization.others;
  }
};
