import { FunctionComponent } from "react";
import SelectBoxItem from "../../models/SelectBoxItem";
import { FormField, Select, SingleSelectChangeEvent } from "@velocity/ui";

interface SelectBoxProps {
  items?: SelectBoxItem[];
  id?: string;
  label?: string;
  value?: string;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  isError?: boolean;
  errorMessage?: string;
  onChange?: (event: SingleSelectChangeEvent) => void;
}

const SelectBox: FunctionComponent<SelectBoxProps> = ({
  id,
  items,
  label,
  value,
  multiple,
  disabled,
  className,
  required,
  isError,
  errorMessage,
  onChange,
}: SelectBoxProps) => {
  return (
    <FormField
      label={label}
      id={id}
      style={{
        width: "100%",
      }}
      required={required}
      className={className}
      error={isError}
      errorMessage={errorMessage}
    >
      <Select
        id={id}
        name={id}
        aria-label={label}
        onChange={onChange}
        value={value}
        //multiple={multiple}
        disabled={disabled}
      >
        {items?.map((item) => (
          <Select.Option key={item.value?.toString()} value={item.value}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    </FormField>
  );
};

export default SelectBox;
