import "@velocity/styling/themes/ayvens/theme.css";
import AyvensTheme from "@velocity/styling/themes/ayvens/theme";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { VelocityProvider } from "@velocity/ui";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <VelocityProvider theme={AyvensTheme}>
      <App />
    </VelocityProvider>
  </React.StrictMode>
);
