import { Client } from "../api/Client";
import { useQuery } from "@tanstack/react-query";

export const useGetPartners = (
  licensePlate: string,
  countryId: number,
  languageId: number,
  latitude: number,
  longitude: number,
  coopAreas: number[],
  coopSubAreas: number[]
) => {
  const client = new Client();

  return useQuery({
    queryKey: [
      "getPartners",
      licensePlate,
      countryId,
      languageId,
      latitude,
      longitude,
      coopAreas,
      coopSubAreas,
    ],
    queryFn: () =>
      client.partners(
        licensePlate,
        countryId,
        languageId,
        latitude,
        longitude,
        coopAreas,
        coopSubAreas
      ),
    enabled: coopAreas?.length > 0 || coopSubAreas?.length > 0,
    staleTime: 2000, // re-fetch after 2 seconds
  });
};
