import { useState, useEffect } from "react";

type StateSetter<T> = (state: T) => T;
type SetStateFn<T> = (state: StateSetter<T>) => void;
type SetStateObj<T> = (state: T) => void;
type SetState<T> = SetStateFn<T> & SetStateObj<T>;

class Store<T> {
  private setters: Array<React.Dispatch<React.SetStateAction<T>>> = [];
  private state: T;

  public readonly name: string;

  constructor(name: string, state: T) {
    this.name = name;
    this.state = state;
    this.setState = this.setState.bind(this);
    this.registerSetter = this.registerSetter.bind(this);
    this.unregisterSetter = this.unregisterSetter.bind(this);
  }

  public getState(): T {
    return this.state;
  }

  public setState(state: T): void;
  public setState(state: StateSetter<T>): void;
  public setState(state: any) {
    if (typeof state === "function") {
      this.state = state(this.state);
    } else {
      this.state = state;
    }
    this.setters.forEach((setter) => setter(this.state));
  }

  public registerSetter(setter: React.Dispatch<React.SetStateAction<T>>) {
    if (!this.setters.includes(setter)) {
      this.setters.push(setter);
    }
  }

  public unregisterSetter(setter: React.Dispatch<React.SetStateAction<T>>) {
    this.setters = this.setters.filter((s) => s !== setter);
  }
}

const stores: Record<string, Store<any>> = {};

export function createStore<T>(name: string, state: T) {
  if (typeof name !== "string") {
    throw new Error("The store name must be a string");
  }
  if (stores[name]) {
    throw new Error(`A store with name ${name} already exists`);
  }
  stores[name] = new Store<T>(name, state);
}

function useStore<T>(name: string): [T, SetState<T>];
function useStore<T>(name: string, initialState: T): [T, SetState<T>];
function useStore<T>(name: string, initialState?: any): [T, SetState<T>] {
  if (!stores[name]) {
    if (initialState !== undefined) {
      createStore<T>(name, initialState);
    } else {
      throw new Error(
        `A store with name ${name} does not exist and no initial state was provided for creating the store.`
      );
    }
  }
  const store = stores[name] as Store<T>;
  const [state, setState] = useState<T>(store.getState());

  useEffect(() => {
    store.registerSetter(setState);
    return () => {
      store.unregisterSetter(setState);
    };
  }, [store]);

  return [state, store.setState as SetState<T>];
}

export default useStore;
