export const openNavigate = (lat: number, long: number) => {
  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1
  ) {
    window.open(
      `comgooglemaps-x-callback://maps.google.com/maps?daddr=${lat},${long}&saddr=Current%20Location`
    );
  } else {
    window.open(`http://maps.google.com/maps?daddr=${lat},${long}&saddr=`);
  }
};
