import React from "react";

import { Partner } from "../../../api/Client";
import useStore from "../../../hooks-store/store";
import { useEffect, useState, forwardRef } from "react";
import {
  getAddressFormat,
  getMarkerFormat,
  getServiceIcon,
  getServiceTitle,
} from "../../../helpers/formatters";
import MapMarker from "../../../models/MapMarker";
import { MouseEvent } from "react";
import MapSimple from "./MapSimple";
import { localization } from "../../../localization/localizedStrings";
import { Grid, Text } from "@velocity/ui";

type Props = {};

const MapDrawerContent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [mapMarkers, setMapMarker] = useState<MapMarker[]>([]);
  const [partnerStore, setPartnerStore] = useStore<Partner>("partner");
  const [address, setAddress] = useState<string | null>("");

  const handleOnMoreInfoClick = (
    event: MouseEvent<HTMLButtonElement>,
    partner: Partner
  ) => {};

  const handleOnNavigationClick = (
    event: MouseEvent<HTMLButtonElement>,
    partner: Partner
  ) => {};

  useEffect(() => {
    if (partnerStore) {
      setAddress(getAddressFormat(partnerStore));

      const mapMarkers: MapMarker[] = getMarkerFormat(
        [partnerStore],
        handleOnMoreInfoClick,
        handleOnNavigationClick
      );

      setMapMarker(mapMarkers);
    }
  }, [partnerStore]);

  return (
    <div ref={ref}>
      <Grid>
        <Grid.Item SM={6} LG={3}>
          <Text className="drawer-print-title">
            <h2>
              {partnerStore?.name +
                (partnerStore?.distance
                  ? ` (${Math.round(partnerStore?.distance / 1000)} km)`
                  : "")}
            </h2>
          </Text>
          <Text>{localization.address}</Text>
          <Text>{address && <strong>{address}</strong>}</Text>
          <Text style={{ marginTop: "1rem" }}>{localization.contactGsm}</Text>
          <Text>
            {partnerStore?.gsm && (
              <strong>
                <a href={`tel:${partnerStore?.gsm}`}>{partnerStore?.gsm}</a>
              </strong>
            )}
          </Text>
          <Text style={{ marginTop: "1rem" }}>{localization.websSite}</Text>
          <Text>
            {partnerStore?.website && (
              <strong>
                <a
                  href={`${partnerStore?.website}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {partnerStore?.website}
                </a>
              </strong>
            )}
          </Text>
        </Grid.Item>
        <Grid.Item SM={6} LG={3}>
          <Text style={{ marginTop: "3.5rem" }}>
            {localization.contactEmail}
          </Text>
          <Text>
            {partnerStore?.email && (
              <strong>
                <a href={`mailTo:${partnerStore?.email}`}>
                  {partnerStore?.email}
                </a>
              </strong>
            )}
          </Text>
          <Text style={{ marginTop: "1rem" }}>{localization.openingHours}</Text>
          <Text>
            {partnerStore?.openingHours && (
              <strong>{partnerStore?.openingHours}</strong>
            )}
          </Text>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item SM={12} LG={12}>
          <div className="offcanvas-text-wrapper">
            <h4>{localization.otherServices}</h4>
            {partnerStore?.servicesCollection &&
              partnerStore?.servicesCollection.length > 0 && (
                <div>
                  {partnerStore?.servicesCollection?.map((service) => (
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                      }}
                      key={service.serviceType}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "center",
                        }}
                      >
                        <img
                          alt={service?.serviceType?.toString()}
                          className="pictogram"
                          src={getServiceIcon(service.serviceType)}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: "1rem",
                          display: "flex",
                          alignItems: "start",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <strong>
                            {getServiceTitle(service.serviceType)}
                          </strong>
                        </div>
                        {service.information?.map((info) => (
                          <div key={info.subnetwork}>
                            {info.notes?.map((note) => (
                              <Text>{note}</Text>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </Grid.Item>
        <Grid.Item SM={12} LG={12}>
          <div style={{ marginTop: "1rem" }}>
            <MapSimple item={{ partnerInfo: partnerStore }} height={340} />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
});

export default MapDrawerContent;
