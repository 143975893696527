import { FunctionComponent, useEffect, useState, MouseEvent } from "react";
import React from "react";

import Map from "./Map";
import MapCardsPanel from "./MapCardsPanel";
import { useGetPartners } from "../../../hooks/useGetPartners";
import { getMarkerFormat } from "../../../helpers/formatters";
import MapMarker from "../../../models/MapMarker";
import Loader from "../../ui/Loader";
import { useParams, useSearchParams } from "react-router-dom";
import { Partner, PartnerFilter } from "../../../api/Client";
import useStore from "../../../hooks-store/store";
import MapFilter from "./MapFilter";
import { Card, Grid } from "@velocity/ui";
import { localization } from "../../../localization/localizedStrings";
import { getCountryShortcut } from "../../../helpers/country";
import MapFilterDrawer from "./MapFilterDrawer";
import AddressAutoComplete from "./AddressAutoComplete";

interface MapContentProps {
  defaultFilter?: PartnerFilter;
  countryId?: number;
  languageId?: number;
}

const MapContent: FunctionComponent<MapContentProps> = ({
  defaultFilter,
  countryId,
  languageId,
}: MapContentProps) => {
  const { licensePlate } = useParams();
  const [mapMarkers, setMapMarker] = useState<MapMarker[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [partnersStore, setPartnersStore] = useStore("partners");
  const [openFilterDrawerStore, setOpenFilterDrawerStore] =
    useStore<boolean>("openFilterDrawer");
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);

  const coopAreaParam = searchParams.get("coopArea");
  const coopSubAreaParam = searchParams.get("coopSubArea");
  //const distanceParam = searchParams.get("distance");
  const latParam = searchParams.get("lat");
  const lngParam = searchParams.get("lng");
  const address = searchParams.get("address");

  const lat = latParam
    ? parseFloat(latParam)
    : defaultFilter?.location?.latitude ?? 50.0762369;

  const lng = lngParam
    ? parseFloat(lngParam)
    : defaultFilter?.location?.longitude ?? 14.4502317;

  const coopAreas = coopAreaParam
    ? [parseInt(coopAreaParam)]
    : defaultFilter?.cooperationAreas ?? [];

  const coopSubAreas = coopSubAreaParam
    ? [parseInt(coopSubAreaParam)]
    : defaultFilter?.cooperationAreas ?? [];

  let {
    data: partnersResponse,
    isSuccess: partnersResponseSuccess,
    isLoading,
  } = useGetPartners(
    licensePlate ?? "",
    countryId ?? 0,
    languageId ?? 0,
    lat,
    lng, //distance will be not used on back end
    coopSubAreas.length > 0 ? [] : coopAreas, //if subareas are selected, areas are not used
    coopSubAreas
  );

  const handleOnMoreInfoClick = (
    event: MouseEvent<HTMLButtonElement>,
    partner: Partner
  ) => {};

  const handleOnNavigationClick = (
    event: MouseEvent<HTMLButtonElement>,
    partner: Partner
  ) => {};

  useEffect(() => {
    localization.setLanguage(getCountryShortcut(languageId));
  }, [languageId]);

  useEffect(() => {
    setIsFilterDrawerOpen(openFilterDrawerStore);
  }, [openFilterDrawerStore]);

  useEffect(() => {
    if (partnersResponseSuccess && partnersResponse != null) {
      const mapMarkers: MapMarker[] = getMarkerFormat(
        partnersResponse?.data?.partnersCollection,
        handleOnMoreInfoClick,
        handleOnNavigationClick
      );

      setMapMarker(mapMarkers);
      setPartnersStore(partnersResponse?.data?.partnersCollection! as any);
    }
  }, [partnersResponse, partnersResponseSuccess]); //pridat url params

  const defaultFilterValues: any = {
    coopAreas: coopAreas,
    coopSubAreas: coopSubAreas,
    position: { lat: lat, lng: lng },
    address: address ?? "",
    brands: defaultFilter?.brands ?? [],
    selectedCoopArea: coopAreaParam ? parseInt(coopAreaParam) : 0,
    selectedCoopSubArea: coopSubAreaParam ? parseInt(coopSubAreaParam) : 0,
  };

  return (
    <>
      {/* {partnersResponseSuccess ? ( */}
      <Grid className="map-main-content-container">
        <Grid.Item SM={12} LG={3} className="map-filter-container">
          <Card kind="flat">
            <Card.Body>
              <MapFilter
                id="map-filter"
                countryId={countryId}
                languageId={languageId}
                isMobile={false}
                defaultFilter={defaultFilterValues}
              />
            </Card.Body>
          </Card>
        </Grid.Item>
        <Grid.Item SM={12} LG={3} className="map-card-panel-container">
          <MapCardsPanel
            //distance={distance}
            items={partnersResponse?.data?.partnersCollection}
          />
        </Grid.Item>
        <Grid.Item SM={12} LG={6} className="map-container">
          <Map items={mapMarkers} />
        </Grid.Item>
      </Grid>
      <MapFilterDrawer
        isOpen={openFilterDrawerStore}
        languageId={languageId}
        countryId={countryId}
        defaultFilter={defaultFilterValues}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default MapContent;
