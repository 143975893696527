import { FunctionComponent } from "react";

import MainDialog from "../components/layout/home/MainDialog";

const HomePageNew: FunctionComponent = () => {
  return (
    <div className="bg-image d-flex align-items-center justify-content-center">
      <MainDialog />
    </div>
  );
};

export default HomePageNew;
