import { FunctionComponent } from "react";
import loadingLogo from "../../assets/img/loading_logo.svg";

const Loader: FunctionComponent = () => {
  return (
    <>
      <div className="loader-overlay">
        <div className="loader-container">
          <img src={loadingLogo} alt={"Ayvens logo"} />
          {/* <span className="visually-hidden">Loading...</span> */}
        </div>
      </div>
    </>
  );
};

export default Loader;
