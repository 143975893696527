import { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

interface LegalInformationPageProps {}

const LegalInformationPage: FunctionComponent<LegalInformationPageProps> = (
  props: LegalInformationPageProps
) => {
  return <Container></Container>;
};

export default LegalInformationPage;
