import { Client } from "../api/Client";
import { useQuery } from "@tanstack/react-query";

export const useGetPartnerFilter = (
  countryId: number,
  locationId: number,
  vehicleRegistrationNumber: string | null
) => {
  const client = new Client();

  // const { data: partnerTypeIds } = useQuery({
  //   queryKey: ["getPartnerTypes", countryId, locationId],
  //   queryFn: () => client.partnerTypes(countryId, locationId),
  //   select: (partnerTypes) =>
  //     partnerTypes.data
  //       ? partnerTypes.data?.map((partnerType) => partnerType.id)
  //       : [],
  //   enabled: partnerTypes.length === 0,
  // });

  return useQuery({
    queryKey: [
      "getPartnerFilter",
      countryId,
      locationId,
      vehicleRegistrationNumber,
    ],
    queryFn: () =>
      client.partnerFilter(
        countryId,
        locationId,
        vehicleRegistrationNumber ?? ""
        //"0160"
      ),
    enabled: !!vehicleRegistrationNumber,
  });
};
