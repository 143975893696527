import { SystemIcon } from "@velocity/icons/system";
import { Button, TButtonSize, TButtonVariant } from "@velocity/ui";
import { FunctionComponent } from "react";
import { MouseEvent } from "react";
//import ButtonType from "../../models/ButtonType";

interface CustomButtonProps {
  id?: string;
  text?: string;
  variant: TButtonVariant;
  type?: "button" | "submit" | "reset" | undefined;
  size?: TButtonSize; //"sm" | "lg" | undefined;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  endIcon?: SystemIcon | undefined;
  startIcon?: SystemIcon | undefined;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CustomButton: FunctionComponent<CustomButtonProps> = ({
  id,
  text,
  type,
  variant,
  size,
  className,
  disabled,
  fullWidth,
  endIcon,
  startIcon,
  onClick,
}: CustomButtonProps) => {
  return (
    <Button
      id={id}
      type={type}
      EndIcon={endIcon}
      StartIcon={startIcon}
      aria-label={text}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      className={className}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
