import { useReducer } from "react";
import AppContext from "./AppContext";
import React from "react";

const defaultState = {
  selectedPartnerId: {},
  setSelectedPartnerId: (id: number) => {},
};

const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SELECTED_PARTNER_ID":
      return {
        ...state,
        selectedPartnerId: action.payload,
      };
    default:
      return defaultState;
  }
};

const AppContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, defaultState);

  const setSelectedPartnerId = (id: number) => {
    dispatch({ type: "SET_SELECTED_PARTNER_ID", payload: id });
  };

  const appContext = {
    selectedPartnerId: state.selectedPartnerId,
    setSelectedPartnerId: setSelectedPartnerId,
  };

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
