export const getCountryId = (countryCode: string | undefined) => {
  switch (countryCode?.toLowerCase()) {
    case "sk":
      return 1;
    case "en":
      return 2;
    default:
      return 0;
  }
};

export const getCountryShortcut = (countryId: number | undefined) => {
  switch (countryId) {
    case 1:
      return "sk";
    case 2:
      return "en";
    default:
      return "cs";
  }
};
