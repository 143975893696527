import { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

interface CookiePolicyPageProps {
  // TODO
}

const CookiePolicyPage: FunctionComponent<CookiePolicyPageProps> = (
  props: CookiePolicyPageProps
) => {
  return <Container></Container>;
};

export default CookiePolicyPage;
