import React from "react";
import {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useCallback,
} from "react";
import ButtonType from "../../../models/ButtonType";
import { useGetCooperationAreas } from "../../../hooks/useGetCooperationAreas";
import { useGetCooperationSubAreas } from "../../../hooks/useGetCooperationSubAreas";
import {
  CooperationArea,
  CooperationSubArea,
  Coordinates,
  MapSettingsResponse,
  PartnerFilter,
} from "../../../api/Client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { localization } from "../../../localization/localizedStrings";
import useStore from "../../../hooks-store/store";
import Position from "../../../models/Position";
import SelectBox from "../../ui/SelectBox";
import SelectBoxItem from "../../../models/SelectBoxItem";
import {
  Divider,
  Search,
  SegmentControlSingleChangeEvent,
  SegmentedControl,
  SingleSelectChangeEvent,
  Text,
  useGoogleMapsService,
  Place,
} from "@velocity/ui";
import AddressAutoComplete from "./AddressAutoComplete";
import CustomButton from "../../ui/CustomButton";
import MapFilterValues from "../../../models/MapFilterValues";
import { SearchIcon, LocationIcon } from "@velocity/icons/system";
import { useGetBrands } from "../../../hooks/useGetBrands";

interface MapFilterProps {
  id: string;
  defaultFilter?: MapFilterValues;
  countryId?: number;
  languageId?: number;
  isMobile?: boolean;
}

const MapFilter: FunctionComponent<MapFilterProps> = ({
  id,
  defaultFilter,
  countryId,
  languageId,
  isMobile,
}: MapFilterProps) => {
  const googleMapsService = useGoogleMapsService();
  const [loading, setLoading] = useState<boolean>(false);
  const [appSettingsStore, setAppSettingsStore] =
    useStore<MapSettingsResponse | null>("appSettings");
  const [brandStore, setBrandStore] = useStore<string | undefined>("brand");
  const [openFilterDrawerStore, setOpenFilterDrawerStore] =
    useStore<boolean>("openFilterDrawer");
  const [errorSelection, setErrorSelection] = useState<boolean>(false);
  const [errorSelectionMessage, setErrorSelectionMessage] =
    useState<string>("");

  //find default coordinates in app settings based on default country
  const foundCoordinates: Coordinates[] | undefined =
    appSettingsStore?.data?.coordinates?.filter(
      (item) => item.countryCode === appSettingsStore?.data?.defaultCountry
    );
  const defaultCoordinates: Coordinates | null =
    foundCoordinates?.length! > 0 ? foundCoordinates![0] : null;

  const defaultPosition: Position = {
    lat: defaultFilter?.position?.lat
      ? defaultFilter?.position?.lat
      : parseFloat(defaultCoordinates?.lat as string) ?? 50.0762369,
    lng: defaultFilter?.position?.lng
      ? defaultFilter?.position?.lng
      : parseFloat(defaultCoordinates?.lng as string) || 14.4502317,
  };

  const [positionStore, setPositionStore] = useStore<Position>(
    "position",
    defaultPosition
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedCooperationArea, setSelectedCooperationArea] =
    useState<number>(defaultFilter?.selectedCoopArea ?? 0);
  const [selectedCooperationSubArea, setSelectedCooperationSubArea] =
    useState<number>(defaultFilter?.selectedCoopSubArea ?? 0);
  const [currentPosition, setCurrentPosition] =
    useState<Position>(defaultPosition);
  const [selectedAddress, setSelectedAddress] = useState<string>(
    defaultFilter?.address ?? ""
  );
  const [cooperationAreas, setCooperationAreas] = useState<
    Array<SelectBoxItem> | undefined
  >([]);
  const [cooperationSubAreas, setCooperationSubAreas] = useState<
    Array<SelectBoxItem> | undefined
  >([]);

  const { data: cooperationAreasData, isSuccess: cooperationAreasSuccess } =
    useGetCooperationAreas(countryId, languageId);
  const {
    data: cooperationSubAreasData,
    isSuccess: cooperationSubAreasSuccess,
  } = useGetCooperationSubAreas(countryId, languageId);
  const { data: brandsData, isSuccess: brandsSuccess } = useGetBrands(
    countryId,
    languageId
  );

  // useEffect(() => {
  //   setPositionStore(defaultPosition);
  // }, [defaultPosition]);

  const getDropDownItems = (data: any): Array<SelectBoxItem> | undefined => {
    return data?.map((item: any) => {
      if (item instanceof CooperationArea) {
        return {
          title: item.name!,
          value: item.id?.toString()!,
        } as SelectBoxItem;
      } else if (item instanceof CooperationSubArea) {
        return {
          title: item.name!,
          value: item.id?.toString()!,
          parentId: item?.areaId ?? 0,
        } as SelectBoxItem;
      }
    });
  };

  const handleCooperationAreaChange = (event: SingleSelectChangeEvent) => {
    const selectedCooperationArea = parseInt(event.target.value);
    setSelectedCooperationArea(selectedCooperationArea);

    const subAreas = cooperationSubAreasData?.data?.filter(
      (subArea) =>
        subArea.areaId === selectedCooperationArea ||
        selectedCooperationArea === 0
    );

    const subAreasItems = getDropDownItems(subAreas);
    setCooperationSubAreas(
      subAreasItems?.filter((item) => item.title !== "" && item.title !== null)
    );
  };

  const handleCooperationSubAreaChange = (event: SingleSelectChangeEvent) => {
    setSelectedCooperationSubArea(parseInt(event.target.value));
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value);
  };

  const handleOnFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedCooperationArea != 0) {
      let params = serializeFormQuery(event.target);

      if (positionStore?.lat && positionStore?.lng) {
        params.append("lat", positionStore?.lat?.toString());
        params.append("lng", positionStore?.lng?.toString());
      }

      setSearchParams(params);
      //navigate(window.location.href); //HOTFIX: force navigation to refresh the page (search params are not updated without this)

      setErrorSelection(false);
      setErrorSelectionMessage("");
    } else {
      setErrorSelection(true);
      setErrorSelectionMessage(localization.selectionError);
    }

    if (isMobile) {
      setOpenFilterDrawerStore(false);
    }
  };

  const handleMyLocationSuccess: PositionCallback = async ({ coords }) => {
    const { latitude: lat, longitude: lng } = coords;
    const locationByCoords = await googleMapsService.findPlaceByLocation({
      lat,
      lng,
    });

    setPositionStore({ lat, lng });
    setSelectedAddress(locationByCoords.formattedAddress);
    setLoading(false);
  };

  const handleMyLocationError: PositionErrorCallback = (positionError) => {
    console.log(positionError);
    setSelectedAddress("");
    setLoading(false);
  };

  const handleOnMyLocationClick = (event: MouseEvent<HTMLButtonElement>) => {
    setLoading(true);

    navigator?.geolocation.getCurrentPosition(
      handleMyLocationSuccess,
      handleMyLocationError
    );
  };

  const serializeFormQuery = (form: EventTarget | null) => {
    let params = new URLSearchParams();
    if (form) {
      const formElements = (form as HTMLFormElement).elements;
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i] as HTMLInputElement;
        if (element.value && element.value !== "0") {
          if (element.id) {
            params.append(element.id, element.value);
          } else if (element.name) {
            //id is not working on select elements, just name
            params.append(element.name, element.value);
          }
        }
      }
    }
    return params;
  };

  const loadBrand = useCallback(() => {
    if (brandsSuccess) {
      const defaultBrand =
        defaultFilter?.brands && defaultFilter?.brands.length > 0
          ? defaultFilter?.brands[0]
          : 0;
      const foundBrand = brandsData?.data?.find(
        (brand) => brand.id === defaultBrand
      );
      setBrandStore(foundBrand?.name);
    }
  }, [brandsData, brandsSuccess, defaultFilter]);

  const loadCooperationAreas = useCallback(() => {
    if (cooperationAreasSuccess) {
      let filteredCooperationAreas: CooperationArea[] | null | undefined =
        cooperationAreasData?.data;

      //use default filter if exists
      // if (defaultFilter?.coopAreas && defaultFilter?.coopAreas.length > 0) {
      //   filteredCooperationAreas = filteredCooperationAreas?.filter((area) => {
      //     return defaultFilter?.coopAreas?.some((defaultArea) => {
      //       return defaultArea === area.id;
      //     });
      //   });
      // }

      const areasItems = getDropDownItems(filteredCooperationAreas);

      areasItems?.unshift({
        title: localization.defaultSelectValue,
        value: "0",
      });
      setCooperationAreas(areasItems);
    }
  }, [cooperationAreasSuccess, cooperationAreasData, defaultFilter]);

  const loadCooperationSubAreas = useCallback(() => {
    if (cooperationSubAreasSuccess) {
      const cooperationSubAreas: CooperationSubArea[] | null | undefined =
        cooperationSubAreasData?.data;
      let filteredCooperationSubAreas: CooperationSubArea[] | undefined = [];
      //use default filter if exists
      // if (
      //   defaultFilter?.coopSubAreas &&
      //   defaultFilter?.coopSubAreas.length > 0
      // ) {
      //   filteredCooperationSubAreas?.filter((subArea) => {
      //     return defaultFilter?.coopSubAreas?.some((defaultSubArea) => {
      //       return defaultSubArea === subArea.id;
      //     });
      //   });
      // }

      if (
        defaultFilter?.selectedCoopArea &&
        defaultFilter?.selectedCoopArea !== 0
      ) {
        filteredCooperationSubAreas = cooperationSubAreas?.filter((subArea) => {
          return defaultFilter?.selectedCoopArea === subArea.areaId;
        });
      }

      const subAreaItems = getDropDownItems(filteredCooperationSubAreas);

      //some items are empty, remove them
      setCooperationSubAreas(
        subAreaItems?.filter((item) => item.title !== "" && item.title !== null)
      );
    }
  }, [cooperationSubAreasSuccess, cooperationSubAreasData, defaultFilter]);

  useEffect(() => {
    loadCooperationAreas();
    loadCooperationSubAreas();
    loadBrand();
  }, [loadCooperationAreas, loadCooperationSubAreas, loadBrand]);

  return (
    <>
      <div style={{ height: "100%" }}>
        <form
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
          onSubmit={handleOnFormSubmit}
        >
          <div style={{ width: "100%", flexGrow: 1 }}>
            {/* <h4>{localization.radius}</h4>
            <Text>{localization.radiusDescription}</Text>
            <SegmentedControl
              kind="form"
              id="distance"
              defaultValue="20000"
              aria-label="Distance selector"
              className="mb-1 mt-1"
              value={selectedDistance}
              onChange={handleDistanceChange}
            >
              {distances.map((item) => (
                <SegmentedControl.Option
                  key={item.value}
                  value={item.value!.toString()}
                >
                  {item.title}
                </SegmentedControl.Option>
              ))}
            </SegmentedControl>
            <Divider />
            <h4 style={{ marginTop: "1rem" }}>{localization.filterTitle}</h4> */}
            <h4>{localization.filterDescription}</h4>
            <SelectBox
              label={localization.services}
              id="coopArea"
              items={cooperationAreas}
              onChange={handleCooperationAreaChange}
              value={selectedCooperationArea.toString()}
              required={true}
              isError={errorSelection}
              errorMessage={errorSelectionMessage}
              className="mb-1 mt-1"
            />
            <SelectBox
              label={localization.serviceType}
              id="coopSubArea"
              items={cooperationSubAreas}
              onChange={handleCooperationSubAreaChange}
              value={selectedCooperationSubArea.toString()}
              disabled={cooperationSubAreas?.length === 0}
              required={true}
              className="mb-1"
            />
            <AddressAutoComplete
              label={localization.address}
              id={"address"}
              placeholder={localization.address}
              value={selectedAddress}
              defaultValue={selectedAddress}
              className="mb-1"
              onChange={handleAddressChange}
            />
          </div>
          <div>
            <Divider />
            <div className="mt-1" style={{ minWidth: "330px" }}>
              <CustomButton
                variant={"primary-filled"}
                type={ButtonType.Submit}
                text={localization.search}
                className="mr-1"
                startIcon={SearchIcon}
              />
              <CustomButton
                variant={"primary-outlined"}
                type={ButtonType.Button}
                text={loading ? "Loading..." : localization.myLocation}
                onClick={handleOnMyLocationClick}
                startIcon={LocationIcon}
              />
            </div>
            {/* <CustomButton
              variant={"primary-outlined"}
              type={ButtonType.Button}
              text={localization.deleteFilter}
              onClick={handleOnReset}
              startIcon={TrashIcon}
            /> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default MapFilter;
