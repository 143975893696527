import "@velocity/styling/themes/ayvens/theme";
import React, { useState } from "react";

import HomePage from "./pages/HomePage";
import MapPage from "./pages/MapPage";
import {
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import PrivacyStatementPage from "./pages/PrivacyStatementPage";
import LegalInformationPage from "./pages/LegalInformation";
import Loader from "./components/ui/Loader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorPage from "./pages/ErrorPage";
import { useCallback, useEffect } from "react";
import { Client, MapSettingsResponse } from "./api/Client";
import useStore from "./hooks-store/store";
import AyvensTheme from "@velocity/styling/themes/ayvens/theme";
import { VelocityProvider } from "@velocity/ui";
import AppContextProvider from "./context/AppContextProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:language",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/map/:language/:licensePlate",
    element: <MapPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cookie-policy",
    element: <CookiePolicyPage />,
  },
  {
    path: "/privacy-statement",
    element: <PrivacyStatementPage />,
  },
  {
    path: "/legal-information",
    element: <LegalInformationPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <LegalInformationPage />,
  },
  {
    path: "/ayvens-global",
    element: <LegalInformationPage />,
  },
  {
    path: "/newsroom",
    element: <LegalInformationPage />,
  },
  {
    path: "/investor-relations",
    element: <LegalInformationPage />,
  },
]);

function AppNew() {
  const [appSettingsStore, setAppSettingsStore] =
    useStore<MapSettingsResponse | null>("appSettings", null);
  const [language, setLanguage] = useState<string>("cs");

  const getAppSettings = useCallback(async () => {
    const client = new Client();
    setAppSettingsStore(await client.appsettings());
  }, []);

  useEffect(() => {
    getAppSettings();
  }, [getAppSettings]);

  const pathName = (window as any).location.pathname;
  //useParams is not working here before the router is initialized
  useEffect(() => {
    const params = pathName.split("/");

    if (params.length >= 2) {
      setLanguage(params[2]);
    }
  }, [pathName]);

  const queryClient = new QueryClient();

  return (
    <>
      {appSettingsStore && (
        <VelocityProvider theme={AyvensTheme} locale={language}>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider>
              <RouterProvider router={router} fallbackElement={<Loader />} />
            </AppContextProvider>
          </QueryClientProvider>
        </VelocityProvider>
      )}
    </>
  );
}

export default AppNew;
