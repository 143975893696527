import React from "react";
import { FunctionComponent, MouseEvent, useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { localization } from "../../../localization/localizedStrings";
import { LanguageSelectorChangeEvent, TopNavigation } from "@velocity/ui/draft";
import { LicensePlate, NotificationBanner, Text } from "@velocity/ui";
import { LanguageCode } from "@velocity/ui/core/TopNavigation/utils/locale";
import { CarfrontIcon, CarsideIcon, FiltersIcon } from "@velocity/icons/system";
// import MapFilterDrawer from "./MapFilterDrawer";
import useStore from "../../../hooks-store/store";

const MapNavigation: FunctionComponent = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>("cs");
  const [openFilterDrawerStore, setOpenFilterDrawerStore] =
    useStore<boolean>("openFilterDrawer");
  const [brandStore, setBrandStore] = useStore<string | undefined>("brand");
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { language, licensePlate } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const localeCodeData: LanguageCode[] = ["cs", "sk", "en"];
  const buttonsParam = searchParams.get("buttons"); //requirement to hide buttons for external mobile application
  const [isButtonsVisible, setIsButtonsVisible] = useState<boolean>(
    buttonsParam === "false" ? false : true
  );

  useEffect(() => {
    setSelectedLanguage(language || "cs");
  }, [language]);

  const handleLanguageClick = (event: LanguageSelectorChangeEvent) => {
    const language = event.value || "cs";
    setSelectedLanguage(language);
    localization.setLanguage(language.toLowerCase());
    navigate(`/map/${language}/${licensePlate}`);
    window.location.reload(); //because of UI Velocity component localization
  };

  const handleFilterClick = () => {
    setOpenFilterDrawerStore(true);
    setIsFilterOpen(true);
  };

  return (
    <>
      <TopNavigation layoutContainerSize="fluid">
        <TopNavigation.Logo component={Link} to="/" />
        <TopNavigation.Widgets>
          <TopNavigation.Widgets.Widget
            Icon={CarfrontIcon}
            aria-label="Change car"
            label={`${
              localization.changeVehicle
            }   |   ${brandStore}   |   ${licensePlate?.toUpperCase()}`}
            containsDropdown={false}
            onClick={() => navigate("/")}
            className="secondary-link navigation-car-button"
          >
            <CarfrontIcon size="l" />
          </TopNavigation.Widgets.Widget>
          {isButtonsVisible && (
            <>
              <TopNavigation.Widgets.Widget
                Icon={CarfrontIcon}
                aria-label="Change car"
                //label={`${licensePlate?.toUpperCase()}`}
                containsDropdown={true}
                onClick={() => navigate("/")}
                className="secondary-link navigation-car-button-mobile"
              >
                <a
                  href="#"
                  className="secondary-link"
                  onClick={() => navigate("/")}
                >
                  {`${localization.changeVehicle} `}
                </a>
              </TopNavigation.Widgets.Widget>
              <TopNavigation.Widgets.Widget
                Icon={FiltersIcon}
                aria-label="Filters"
                containsDropdown={false}
                className="navigation-filter-button"
                onClick={handleFilterClick}
              >
                <FiltersIcon size="l" />
              </TopNavigation.Widgets.Widget>
            </>
          )}
        </TopNavigation.Widgets>
        <TopNavigation.LanguageSelector
          languageCodes={localeCodeData}
          value={selectedLanguage}
          onChange={handleLanguageClick}
        />
      </TopNavigation>
    </>
  );
};

export default MapNavigation;
