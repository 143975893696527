import { FunctionComponent, memo } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import MapMarker from "../../../models/MapMarker";
import searchCar from "../../../assets/img/search_car.png";
//import findCarIcon from "../../../assets/img/find_my_car.svg";

interface MapSimpleProps {
  item: MapMarker;
  width?: number;
  height?: number;
}

const MapSimple: FunctionComponent<MapSimpleProps> = ({
  item,
  width,
  height,
}: MapSimpleProps) => {
  const containerStyle = {
    width: width ? width?.toString() + "px" : "100%",
    height: height ? height.toString() + "px" : "70vh",
    borderRadius: "0.75rem",
  };

  const position = {
    lat: item.partnerInfo?.latitude as number,
    lng: item.partnerInfo?.longitude as number,
  };

  return (
    <div className="map-simple-container">
      <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={15}>
        <MarkerF
          key={item.partnerInfo.id}
          icon={searchCar} //{findCarIcon}
          position={position}
        ></MarkerF>
      </GoogleMap>
    </div>
  );
};

export default memo(MapSimple);
