import { FunctionComponent, useCallback, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import useStore from "../../../hooks-store/store";
import Position from "../../../models/Position";
import TextBox from "../../ui/TextBox";
import React, { KeyboardEvent, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";

interface AddressAutoCompleteProps {
  id?: string;
  value?: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  onChange?: (event: any) => void;
}

const AddressAutoComplete: FunctionComponent<AddressAutoCompleteProps> = ({
  id,
  label,
  placeholder,
  disabled,
  readonly,
  value,
  defaultValue,
  className,
  onChange,
}: AddressAutoCompleteProps) => {
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete>();

  const [positionStore, setPositionStore] = useStore<Position>("position");
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>(
    value
  );

  function onLoad(autocomplete: google.maps.places.Autocomplete) {
    setSearchResult(autocomplete);
  }

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.key === "Enter" && e.preventDefault();
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      place.formatted_address && setSelectedAddress(place.formatted_address);

      if (place.geometry) {
        setPositionStore({
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
        });
      }

      if (onChange) {
        onChange({
          target: {
            value: place.formatted_address,
          },
        });
      }
    }
  };

  return (
    <Autocomplete
      onPlaceChanged={onPlaceChanged}
      onLoad={onLoad}
      className={className}
    >
      <TextBox
        id={id}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        readonly={readonly}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyDown={handleOnKeyDown}
      />
    </Autocomplete>
  );
};

export default AddressAutoComplete;
