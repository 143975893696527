import { FunctionComponent } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/ui/Loader";
import { getCountryId } from "../helpers/country";
import MapFilterValues from "../models/MapFilterValues";
import useStore from "../hooks-store/store";
import { MapSettingsResponse, Partner, PartnerFilter } from "../api/Client";
import { useJsApiLoader } from "@react-google-maps/api";
import MapNavigation from "../components/layout/map/MapNavigation";
import {
  Footer,
  FullScreenProcess,
  GoogleMapsContextProvider,
  LayoutContainer,
} from "@velocity/ui";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  XIcon,
} from "@velocity/icons/system";
import MapContent from "../components/layout/map/MapContent";
import { localization } from "../localization/localizedStrings";
import { useGetPartnerFilter } from "../hooks/useGetPartnerFilter";
import { Position } from "google-map-react";

const libraries: any = ["places"];

const MapPage: FunctionComponent = () => {
  const [appSettingsStore, setAppSettingsStore] =
    useStore<MapSettingsResponse | null>("appSettings", null);
  const { licensePlate, language } = useParams();

  const languageId = getCountryId(
    language ?? appSettingsStore?.data?.defaultLanguage ?? "cs"
  );

  const countryId = getCountryId(
    appSettingsStore?.data?.defaultCountry ?? "cz"
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appSettingsStore?.data?.googleApiKey!,
    libraries: libraries,
  });

  const [partnerFilterStore, setPartnerFilterStore] =
    useStore<PartnerFilter | null>("partnerFilter", null);
  const [openFilterDrawerStore, setOpenFilterDrawerStore] = useStore<boolean>(
    "openFilterDrawer",
    false
  );
  const [partnersStore, setPartnersStore] = useStore("partners", []);
  const [brandStore, setBrandStore] = useStore<string | undefined>("brand", "");
  const [filterStore, setFilterStore] = useStore<MapFilterValues>(
    "filter",
    {} as MapFilterValues
  );
  const [partnerStore, setPartnerStore] = useStore<Partner>(
    "partner",
    {} as Partner
  );
  const [positionStore, setPositionStore] = useStore<Position>("position", {
    lat: 50.0762369,
    lng: 14.4502317,
  } as Position);

  const {
    data: filteredPartnersResponse,
    isSuccess: filteredPartnersResponseSuccess,
    isLoading: filteredPartnersResponseLoading,
  } = useGetPartnerFilter(countryId, languageId, licensePlate || "");

  return (
    <>
      {isLoaded && (
        <GoogleMapsContextProvider
          apiKey={appSettingsStore?.data?.googleApiKey!}
          libraries={libraries}
        >
          <>
            <>
              <FullScreenProcess style={{ backgroundColor: "#f6f6f6" }}>
                <MapNavigation />
                <FullScreenProcess.Content className="map-full-screen-container">
                  <LayoutContainer
                    size="fluid"
                    className="map-layout-container"
                  >
                    <MapContent
                      countryId={countryId}
                      languageId={languageId}
                      defaultFilter={
                        partnerFilterStore ?? filteredPartnersResponse?.data
                      }
                    />
                  </LayoutContainer>
                </FullScreenProcess.Content>
                <Footer
                  className="main-footer-container"
                  layoutContainerSize="fluid"
                  copyright="© 2024 Ayvens"
                  variant="brand"
                >
                  <Footer.Legal>
                    <Footer.Legal.Link
                      href="https://www.ayvens.com/cs-cz/ochrana-osobnich-udaju/"
                      target="_blank"
                    >
                      {localization.privacyStatement}
                    </Footer.Legal.Link>
                    <Footer.Legal.Link
                      href="https://www.ayvens.com/cs-cz/pravidla-pro-soubory-cookie/"
                      target="_blank"
                    >
                      {localization.cookiePolicy}
                    </Footer.Legal.Link>
                    <Footer.Legal.Link
                      href="https://www.ayvens.com/cs-cz/pro-obchodni-partnery/"
                      target="_blank"
                    >
                      {localization.termsAndConditions}
                    </Footer.Legal.Link>
                    <Footer.Legal.Link
                      href="https://www.ayvens.com"
                      target="_blank"
                    >
                      {localization.ayvensGlobal}
                    </Footer.Legal.Link>
                    <Footer.Legal.Link
                      href="https://drive.ayvens.cz/cs/magazin"
                      target="_blank"
                    >
                      {localization.newsroom}
                    </Footer.Legal.Link>
                    <Footer.Legal.Link href="https://www.ayvens.com/-/media/ayvens/public/cz/pdf-brozury/ayvens-pro-oznamovatele.pdf?rev=-1">
                      {localization.investorRelations}
                    </Footer.Legal.Link>
                  </Footer.Legal>
                  <Footer.Social>
                    <Footer.Social.Link
                      href="https://www.facebook.com/AyvensCZ"
                      target="_blank"
                      Icon={FacebookIcon}
                      aria-label="Facebook"
                    />
                    <Footer.Social.Link
                      href="https://twitter.com/ayvens_group?lang=cs&elqTrackId=37e018bc464b42b687e8c2b4bc966262"
                      target="_blank"
                      Icon={XIcon}
                      aria-label="X"
                    />
                    <Footer.Social.Link
                      href="https://www.instagram.com/ayvens_global/?elqTrackId=7386181aa92d46939c207034c3ee2749"
                      target="_blank"
                      Icon={InstagramIcon}
                      aria-label="Instagram"
                    />
                    <Footer.Social.Link
                      href="https://cz.linkedin.com/company/ald-automotive-czech?elqTrackId=b52778e17f5f4db8b33722d800ea81e6"
                      target="_blank"
                      Icon={LinkedinIcon}
                      aria-label="Linkedin"
                    />
                  </Footer.Social>
                </Footer>
              </FullScreenProcess>
            </>
            {filteredPartnersResponseLoading && <Loader />}
          </>
        </GoogleMapsContextProvider>
      )}
    </>
  );
};

export default MapPage;
