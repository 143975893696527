import React from "react";
import { FormField, Input } from "@velocity/ui";
import { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";

interface TextBoxProps {
  id?: string;
  value?: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  isValid?: boolean;
  validationText?: string;
  className?: string;
  loading?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const TextBox: FunctionComponent<TextBoxProps> = ({
  id,
  value,
  defaultValue,
  label,
  placeholder,
  disabled,
  readonly,
  required,
  isValid,
  validationText,
  className,
  loading,
  onChange,
  onBlur,
  onKeyDown,
}: TextBoxProps) => {
  return (
    <FormField
      label={label}
      errorMessage={validationText}
      required
      className={className}
      style={{
        width: "100%",
      }}
    >
      <Input
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        aria-label={label}
        value={value}
        defaultValue={defaultValue}
        required={required}
        id={id}
        loading={loading}
      />
    </FormField>
  );
};

export default TextBox;
