import React, { useState, useContext, useRef, createRef } from "react";
import { FunctionComponent, MouseEvent, useEffect } from "react";
import MapCard from "./MapCard";
import useStore from "../../../hooks-store/store";
import { Partner } from "../../../api/Client";
import { localization } from "../../../localization/localizedStrings";
import { Card, Divider, Text } from "@velocity/ui";
import AppContext from "../../../context/AppContext";
interface MapCardsPanelProps {
  id?: string;
  items?: Partner[] | null | undefined;
  //distance?: number;
}

const MapCardsPanel: FunctionComponent<MapCardsPanelProps> = ({
  id,
  items,
}: //distance,
MapCardsPanelProps) => {
  const appCtx = useContext(AppContext) as any;
  const selectedCardRef = useRef<HTMLDivElement | null>(null);

  const [partnerStore, setPartnerStore] = useStore<Partner | null>(
    "partner",
    null
  );
  const [openDrawerStore, setOpenDrawerStore] = useStore<boolean>(
    "openDrawer",
    false
  );

  useEffect(() => {
    if (selectedCardRef.current && appCtx.selectedPartnerId) {
      selectedCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCardRef, appCtx.selectedPartnerId]);

  const handleCardClick = (
    event: MouseEvent<HTMLDivElement>,
    data: Partner | null
  ) => {
    setPartnerStore(data);
    setOpenDrawerStore(false);

    appCtx.setSelectedPartnerId(data?.id);
  };

  return (
    <Card kind="flat">
      <Card.Body>
        <div className="map-card-panel-header">
          <h4>{localization.servicePlaces}</h4>
          <Text>
            {localization.formatString(
              localization.servicePlacesDescription,
              items?.length?.toString() ?? "0"
              //(distance != null ? distance / 1000 : 20).toString() ?? "20"
            )}
          </Text>
          <Divider />
        </div>
        <div className="map-card-panel">
          <div>
            {items?.map((item) => (
              <div
                key={item.id}
                className="card-wrapper"
                ref={
                  item.id === appCtx.selectedPartnerId ? selectedCardRef : null
                }
              >
                <MapCard
                  //key={item.id}
                  data={item}
                  width="95%"
                  active={
                    //partnerStore?.id === item.id ||
                    appCtx.selectedPartnerId === item.id
                  }
                  //ref={(el: HTMLDivElement) => (itemRefs[item.id!] = el)}
                  onClick={(event: any) => handleCardClick(event, item)}
                />
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MapCardsPanel;
