import { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

interface PrivacyStatementPageProps {}

const PrivacyStatementPage: FunctionComponent<PrivacyStatementPageProps> = (
  props: PrivacyStatementPageProps
) => {
  return <Container></Container>;
};

export default PrivacyStatementPage;
