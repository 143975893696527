import { FunctionComponent, useEffect, useState } from "react";
import { PartnerFilter } from "../../../api/Client";
import useStore from "../../../hooks-store/store";
import MapFilter from "./MapFilter";
import { Drawer } from "@velocity/ui";
import { localization } from "../../../localization/localizedStrings";
import React from "react";

interface MapFilterDrawerProps {
  isOpen?: boolean;
  countryId?: number;
  languageId?: number;
  defaultFilter?: PartnerFilter;
}

const MapFilterDrawer: FunctionComponent<MapFilterDrawerProps> = ({
  isOpen,
  countryId,
  languageId,
  defaultFilter,
}: MapFilterDrawerProps) => {
  const [openFilterDrawerStore, setOpenFilterDrawerStore] =
    useStore<boolean>("openFilterDrawer");
  const [show, setShow] = useState<boolean | undefined>(isOpen);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(openFilterDrawerStore);
  }, [openFilterDrawerStore]);

  return (
    <Drawer
      title={localization.filterSection}
      onClose={handleClose}
      open={show}
      size={"s"}
      withBackdropColor={true}
      style={{ backgroundColor: "#FFFFFF" }}
      className="map-filter-drawer"
    >
      <Drawer.Content>
        <MapFilter
          id="map-drawer-filter"
          countryId={countryId}
          languageId={languageId}
          isMobile={true}
          defaultFilter={defaultFilter}
        />
      </Drawer.Content>
    </Drawer>
  );
};

export default MapFilterDrawer;
