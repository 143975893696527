import { Client } from "../api/Client";
import { useQuery } from "@tanstack/react-query";

export const useGetAppSettings = () => {
  const client = new Client();
  return useQuery({
    queryKey: ["getAppSettings"],
    queryFn: () => client.appsettings(),
    staleTime: 1000 * 60 * 60,
  });
};
