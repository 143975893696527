import React, { forwardRef, ForwardRefExoticComponent } from "react";

import { MouseEvent, FunctionComponent } from "react";
//import useStore from "../../../hooks-store/store";
import { Partner } from "../../../api/Client";
import { getAddressFormat } from "../../../helpers/formatters";
import { Card } from "@velocity/ui";

export interface MapCardProps {
  data: Partner | null;
  width?: string;
  height?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  active?: boolean;
}

const MapCard: FunctionComponent<MapCardProps> = ({
  data,
  width,
  height,
  onClick,
  active,
}: MapCardProps) => {
  return (
    <Card
      kind="elevated"
      id={data?.id?.toString()}
      style={{
        width: width,
        height: height,
        // marginTop: "1rem",
      }}
      className={`card-container ${
        //partnerStore?.id === data?.id ? "active" : ""
        active ? "active" : ""
      }`}
      onClick={onClick}
      //rootRef={ref}
    >
      <Card.Body className="card-container-body">
        <div className="card-container-title">
          <strong>{data?.name}</strong>
        </div>
        <div>{getAddressFormat(data)}</div>
      </Card.Body>
    </Card>
  );
};

export default MapCard;
