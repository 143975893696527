import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import useStore from "../../../hooks-store/store";
import { useGetAppSettings } from "../../../hooks/useGetAppSettings";
import {
  Card,
  Divider,
  FullScreenProcess,
  LayoutContainer,
  LinkStandalone,
  Text,
} from "@velocity/ui";
import { AyvensLogo } from "@velocity/icons/logo";
import { LocaleSelector, LocaleSelectorOption } from "@velocity/ui-lab";
import TextBox from "../../ui/TextBox";
import CustomButton from "../../ui/CustomButton";
import { localization } from "../../../localization/localizedStrings";
import { useGetPartnerFilter } from "../../../hooks/useGetPartnerFilter";
import { getCountryId } from "../../../helpers/country";
import { Client, PartnerFilter } from "../../../api/Client";
import Loader from "../../ui/Loader";

const MainDialog: FunctionComponent = () => {
  const navigate = useNavigate();
  const { language: languageParam } = useParams();
  const [language, setLanguage] = useState<string>("");
  const [formValidated, setFormValidated] = useState(true);
  const [licensePlateExists, setLicensePlateExists] = useState<boolean>(true);
  const [licensePlate, setLicensePlate] = useState<string>(""); //2SC0160, SXX 21-99
  const [finalLicensePlate, setFinalLicensePlate] = useState<string | null>(
    null
  ); //this is for calling the api with the correct license plate on blur
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isLicensePlateFound, setIsLicensePlateFound] = useState<boolean>(true);
  const [countryId, setCountryId] = useState<number>(0);
  const [validationText, setValidationText] = useState<string>("");
  const [languageStore, setLanguageStore] = useStore<string>(
    "language",
    languageParam ?? "cs"
  );
  const [languages, setLanguages] = useState<LocaleSelectorOption[]>([
    { value: "en", label: "English" },
  ]);
  const [languageLabels, setLanguageLabels] = useState<string[]>(["English"]);

  const [partnerFilterStore, setPartnerFilterStore] =
    useStore<PartnerFilter | null>("partnerFilter", null);

  const { data: appSettingsResponse, isSuccess: appSettingsResponseSuccess } =
    useGetAppSettings();

  // const {
  //   data: filteredPartnersResponse,
  //   isSuccess: filteredPartnersResponseSuccess,
  //   isLoading: filteredPartnersLoading,
  //   error: filteredPartnersError,
  //   refetch: refetchFilteredPartners,
  // } = useGetPartnerFilter(countryId, countryId, finalLicensePlate);

  //if (filteredPartnersError) console.log(filteredPartnersError);

  useEffect(() => {
    if (appSettingsResponseSuccess && appSettingsResponse) {
      const defaultLanguage = languageParam
        ? languageParam
        : appSettingsResponse.data?.defaultLanguage! ?? "cs";
      const defaultCountry = appSettingsResponse.data?.defaultCountry! ?? "cz";

      //set default language and country
      setLanguageStore(defaultLanguage);
      setCountryId(getCountryId(defaultCountry));

      //set languages array
      if (defaultCountry && defaultCountry.toLowerCase() === "sk") {
        if (languages.find((l) => l.value === "sk") === undefined) {
          setLanguages((oldArray) => [
            { value: "sk", label: "Slovenčina" },
            ...oldArray,
          ]);
        }
      } else {
        if (languages.find((l) => l.value === "cs") === undefined) {
          setLanguages((oldArray) => [
            { value: "cs", label: "Čeština" },
            ...oldArray,
          ]);
        }
      }

      //set language labels array
      // if (defaultCountry && defaultCountry.toLowerCase() === "sk") {
      //   setLanguageLabels((oldArray) => ["Slovenčina", ...oldArray]);
      // } else {
      //   setLanguageLabels((oldArray) => ["Čeština", ...oldArray]);
      // }

      //set default language
      switch (defaultLanguage) {
        case "en":
          setLanguage("English");
          break;
        case "sk":
          setLanguage("Slovenčina");
          break;
        default:
          setLanguage("Čeština");
          break;
      }

      localization.setLanguage(defaultLanguage);
    }
  }, [
    appSettingsResponseSuccess,
    appSettingsResponse,
    //setLanguageStore,
    //languages,
    //languageLabels,
    languageParam,
  ]);

  const handleLanguageChange = (languageCode: string | null) => {
    //if (appSettingsResponseSuccess && appSettingsResponse) {
    const lan = languages.find((l) => l.value === languageCode);
    setLanguageStore(lan?.value!);
    localization.setLanguage(lan?.value ?? "cs");
    setLanguage(lan?.label ?? "Čeština");
    navigate(`/${lan?.value ?? "cs"}`);
    //}
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setFinalLicensePlate(licensePlate);

  //     if (licensePlate && !filteredPartnersLoading) {
  //       if (
  //         filteredPartnersResponseSuccess &&
  //         filteredPartnersResponse?.data &&
  //         filteredPartnersResponse.status !== "ERROR"
  //       ) {
  //         setValidationText("");
  //         setLicensePlateExists(true);
  //         setFormValidated(true);
  //       } else {
  //         setLicensePlateExists(false);
  //         setFormValidated(false);
  //         setValidationText(localization.vehicleLicenseNotFound);
  //       }
  //     }
  //   }, 2000);
  //   return () => clearTimeout(timeoutId);
  // }, [
  //   licensePlate,
  //   filteredPartnersResponseSuccess,
  //   filteredPartnersResponse,
  //   filteredPartnersLoading,
  // ]);

  const handleLicensePlateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredLicensePlate = event.target.value;

    //TODO: uncomment this
    //   .replace(" ", "")
    //   .replace("-", "");
    // const licensePlateRegex = /^([a-zA-Z0-9]+)$/;
    // const isValid = licensePlateRegex.test(enteredLicensePlate);

    // if (isValid) {
    //   setValidationText("");
    // } else {
    //   setValidationText(localization.vehicleLicensePlateErrorMessage);
    // }

    //setFinalLicensePlate(enteredLicensePlate);
    setLicensePlate(enteredLicensePlate);
  };

  // const handleSubmit = useCallback(
  //   (event: FormEvent<HTMLFormElement>) => {
  //     event.preventDefault();

  //     // const form = event.currentTarget;
  //     // if (form.checkValidity() === false) {

  //     setFinalLicensePlate(licensePlate);
  //     refetchFilteredPartners();

  //     //setTimeout(() => {
  //     if (
  //       filteredPartnersResponseSuccess &&
  //       filteredPartnersResponse?.data
  //       //&&
  //       //formValidated
  //     ) {
  //       setPartnerFilterStore(filteredPartnersResponse?.data!);

  //       setFormValidated(true);
  //       navigate(`/map/${languageStore}/${finalLicensePlate}`);
  //       setValidationText("");
  //       setLicensePlateExists(true);
  //     } else if (filteredPartnersResponse?.status === "ERROR") {
  //       // event.preventDefault();
  //       // event.stopPropagation();
  //       setLicensePlateExists(false);
  //       setValidationText(localization.vehicleLicenseNotFound);
  //     }
  //     // }, 2000);
  //   },
  //   [
  //     filteredPartnersResponseSuccess,
  //     filteredPartnersResponse,
  //     licensePlate,
  //     languageStore,
  //     finalLicensePlate,
  //     setPartnerFilterStore,
  //     navigate,
  //   ]
  // );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsDataLoading(true);

      const client = new Client();
      const response = await client.partnerFilter(
        countryId,
        countryId,
        licensePlate ?? ""
      );

      setIsDataLoading(false);

      if (response.status === "OK") {
        setPartnerFilterStore(response.data!);
        navigate(`/map/${languageStore}/${licensePlate}`);
      } else {
        setLicensePlateExists(false);
        setIsLicensePlateFound(false);
        setValidationText(localization.vehicleLicenseNotFound);
      }
    },
    [countryId, licensePlate, languageStore, navigate, setPartnerFilterStore]
  );

  const handleContactUsClick = useCallback(() => {
    if (appSettingsResponseSuccess && appSettingsResponse) {
      appSettingsResponse.data?.defaultCountry!.toLowerCase() === "sk"
        ? (window.location.href = `mailto:infosk@ayvens.com`)
        : (window.location.href = `mailto:infocz@ayvens.com`);
    }
  }, [appSettingsResponseSuccess, appSettingsResponse]);

  return (
    <>
      {appSettingsResponseSuccess && (
        <FullScreenProcess>
          <div
            style={{
              marginTop: "var(--vel-fixed-spacing-06)",
              display: "flex",
              position: "sticky",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <Card kind="elevated" className="card-without-shadow">
              <Card.Body style={{ margin: "0px" }}>
                <LocaleSelector
                  labelLocation={language}
                  labelLanguage={language}
                  open={false}
                  label={language}
                  languages={languages}
                  onSelectLanguage={handleLanguageChange}
                  {...languageLabels}
                />
              </Card.Body>
            </Card>
          </div>
          <FullScreenProcess.Content>
            <LayoutContainer
              size="fixed"
              style={{
                paddingBlock: "var(--vel-fixed-spacing-06)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                kind="elevated"
                className="card-without-shadow"
                style={{ maxWidth: "25rem" }}
              >
                <Card.Header
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <AyvensLogo size="s" />
                </Card.Header>
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>{localization.welcomeHomePage}</h4>
                  <div className="w-100 text-center">
                    <Text>{localization.welcomeDescription}</Text>
                  </div>
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <TextBox
                      label={localization.licensePlate}
                      validationText={
                        !isLicensePlateFound ? validationText : ""
                      }
                      required
                      className="mt-1"
                      value={licensePlate}
                      onChange={handleLicensePlateChange}
                      loading={isDataLoading}
                    />
                    <div
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        width: "100%",
                      }}
                    >
                      <CustomButton
                        type="submit"
                        fullWidth
                        variant={"primary-filled"}
                        className="mt-1"
                        disabled={isDataLoading}
                        text={
                          isDataLoading
                            ? localization.loading
                            : localization.continue
                        }
                      />
                    </div>
                  </form>
                  {/* <LinkStandalone
                    Icon
                    href="mailTo:technicky.servis@aldautomotive.com"
                    className="mt-1"
                  >
                    {localization.needHelpLink}
                  </LinkStandalone>
                  <div className="mt-1 mb-1 w-100">
                    <Divider />
                  </div>
                  <h4>{localization.mainDialogFooterTitle}</h4>
                  <Text>{localization.mainDialogFooterDescription}</Text> */}
                  <div style={{ width: "100%" }}>
                    <CustomButton
                      fullWidth={true}
                      variant={"primary-outlined"}
                      className="mt-1"
                      text={localization.contactUs}
                      onClick={handleContactUsClick}
                    />
                  </div>
                </Card.Body>
              </Card>
            </LayoutContainer>
          </FullScreenProcess.Content>
        </FullScreenProcess>
      )}
    </>
  );
};

export default MainDialog;
