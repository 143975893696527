import { Client } from "../api/Client";
import { useQuery } from "@tanstack/react-query";

export const useGetCooperationAreas = (
  countryId: number | undefined,
  languageId: number | undefined
) => {
  const client = new Client();
  return useQuery({
    queryKey: ["getCooperationAreas", countryId, languageId],
    queryFn: () => client.cooperationArea(countryId ?? 0, languageId ?? 0),
    staleTime: 1000 * 60 * 60,
  });
};
