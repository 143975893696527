import {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  MouseEvent,
} from "react";
import CustomButton from "../../ui/CustomButton";
import { Partner } from "../../../api/Client";
import useStore from "../../../hooks-store/store";
import { getAddressFormat } from "../../../helpers/formatters";
import { openNavigate } from "../../../helpers/navigation";
import MapDrawerContent from "./MapDrawerContent";
import { useReactToPrint } from "react-to-print";
import { Drawer } from "@velocity/ui";
import { localization } from "../../../localization/localizedStrings";
import { LocationIcon, PrintIcon } from "@velocity/icons/system";

interface MapDrawerProps {
  open?: boolean;
}

const MapDrawer: FunctionComponent<MapDrawerProps> = ({
  open,
}: MapDrawerProps) => {
  const [show, setShow] = useState<boolean | undefined>(open);
  const [partnerStore, setPartnerStore] = useStore<Partner>("partner");
  const [openDrawerStore, setOpenDrawerStore] = useStore<boolean>("openDrawer");
  const [address, setAddress] = useState<string | null>("");
  const componentPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const handleClose = () => {
    setOpenDrawerStore(false);
    setShow(false);
  };

  const handleNavigate = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openNavigate(partnerStore?.latitude ?? 0, partnerStore?.longitude ?? 0);
  };

  useEffect(() => {
    if (partnerStore) {
      setAddress(getAddressFormat(partnerStore));
    }

    setShow(openDrawerStore);
  }, [partnerStore, openDrawerStore]);

  return (
    <Drawer
      title={
        partnerStore?.name +
        (partnerStore?.distance
          ? ` (${Math.round(partnerStore?.distance / 1000)} km)`
          : "")
      }
      onClose={handleClose}
      open={show}
      size={"l"}
      withBackdropColor={true}
      className="map-drawer-content-container"
    >
      <Drawer.Content>
        <MapDrawerContent ref={componentPrintRef} />
      </Drawer.Content>
      <Drawer.Footer>
        <CustomButton
          text={localization.navigate}
          variant={"primary-filled"}
          onClick={handleNavigate}
          className="me-3 map-drawer-navigate-button"
          startIcon={LocationIcon}
        />
        <CustomButton
          text={localization.print}
          variant={"primary-outlined"}
          onClick={handlePrint}
          startIcon={PrintIcon}
          className="map-drawer-print-button"
        />
      </Drawer.Footer>
    </Drawer>
  );
};

export default MapDrawer;
