import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface IStrings extends LocalizedStringsMethods {
  welcomeHomePage: string;
  welcomeDescription: string;
  vehicleLicensePlateErrorMessage: string;
  vehicleLicenseNotFound: string;
  needHelpLink: string;
  mainDialogFooterTitle: string;
  mainDialogFooterDescription: string;
  continue: string;
  contactUs: string;
  licensePlate: string;
  vehicleNotFound: string;
  partner: string;
  brand: string;
  vehicleRegistrationNumber: string;
  address: string;
  radius: string;
  radiusDescription: string;
  myLocation: string;
  show: string;
  print: string;
  pleaseSelect: string;
  allBrands: string;
  placeholder: string;
  titleResults: string;
  titleMap: string;
  filterSection: string;
  filterTitle: string;
  filterDescription: string;
  headerName: string;
  headerAddress: string;
  headerDistance: string;
  headerContact: string;
  services: string;
  serviceType: string;
  additionalServices: string;
  contactGsm: string;
  contactEmail: string;
  noResults: string;
  navigate: string;
  search: string;
  deleteFilter: string;
  servicePlaces: string;
  servicePlacesDescription: string;
  otherServices: string;
  privacyStatement: string;
  termsAndConditions: string;
  dataProtection: string;
  cookiePolicy: string;
  ayvensGlobal: string;
  newsroom: string;
  investorRelations: string;
  defaultSelectValue: string;
  moreInformations: string;
  changeVehicle: string;
  legend: string;
  loading: string;
  selectionError: string;
  aircondition: string;
  wash: string;
  pickup: string;
  others: string;
  otherCar: string;
  websSite: string;
  openingHours: string;
}

export const localization: IStrings = new LocalizedStrings({
  cz: {
    welcomeHomePage: "Vítejte v aplikaci Servisy",
    welcomeDescription:
      "Přihlaste se zadáním registrační značky platné smlouvy.",
    vehicleLicensePlateErrorMessage: "Musí být zadána bez mezer a znaků",
    vehicleLicenseNotFound: "Zadaná SPZ nebyla nalezena",
    needHelpLink: "Potřebuji poradit",
    mainDialogFooterTitle: "Něco Vám nefunguje?",
    mainDialogFooterDescription:
      "Kontaktujte naši zákaznickou podporu a vše vyřešíme.",
    continue: "Pokračovat",
    licensePlate: "Registrační značka",
    contactUs: "Kontaktovat podporu",
    vehicleNotFound: "Byla zadána neplatná kombinace pro RZ/PIN",
    partner: "Jakou službu hledáte",
    brand: "Vyberte značku vozu",
    vehicleRegistrationNumber: "Registrační značka",
    address: "Adresa",
    radius: "Hledat v okruhu",
    radiusDescription: "Zvolte si vzdálenost od místa, kde se nacházíte.",
    myLocation: "Moje poloha",
    show: "Zobrazit",
    print: "Tisknout",
    pleaseSelect: "Prosím vyberte",
    allBrands: "Všechny značky",
    placeholder: "Zadejte požadovanou RZ",
    titleResults: "Výsledky",
    titleMap: "Mapa",
    services: "Služby",
    filterSection: "Filtr",
    filterTitle: "Další služby",
    filterDescription: "Vyberte si odpovídající služby k zobrazení",
    serviceType: "Typ služby",
    headerName: "Název",
    headerAddress: "Adresa",
    headerDistance: "Vzdálenost",
    headerContact: "Kontakty",
    additionalServices: "Další služby",
    contactGsm: "Telefon",
    contactEmail: "E-mail",
    noResults: "Tomuto nastavení neodpovídá žádná služba.",
    navigate: "Navigovat",
    search: "Vyhledat",
    deleteFilter: "Smazat filtr",
    servicePlaces: "Seznam servisních míst",
    servicePlacesDescription: "Celkem nalezeno {0} servisních míst",
    otherServices: "Další služby",
    privacyStatement: "Ochrana osobních údajů",
    termsAndConditions: "Obchodní podmínky",
    dataProtection: "Ochrana dat",
    cookiePolicy: "Cookies",
    ayvensGlobal: "Ayvens Global",
    newsroom: "Novinky",
    investorRelations: "Info pro oznamovatele",
    defaultSelectValue: "Prosím vyberte",
    moreInformations: "Více informací",
    changeVehicle: "Změnit vozidlo",
    legend: "Legenda",
    loading: "Načítání",
    selectionError: "Prosím vyberte službu",
    aircondition: "Klimatizace",
    wash: "Mytí",
    pickup: "Vyzvednutí",
    others: "Ostatní",
    otherCar: "Náhradní vozidlo",
    websSite: "Www",
    openingHours: "Otevírací doba",
  },
  sk: {
    welcomeHomePage: "Vitajte v aplikácii Servisy",
    welcomeDescription:
      "Prihláste sa zadaním registračnej značky platnej zmluvy.",
    vehicleLicensePlateErrorMessage: "Musí byť zadaná bez medzier a znakov",
    vehicleLicenseNotFound: "Zadaná SPZ nebola nájdená",
    mainDialogFooterTitle: "Niekde sa Vám niečo nepodarilo?",
    mainDialogFooterDescription:
      "Kontaktujte našu zákaznícku podporu a všetko vyriešime.",
    continue: "Pokračovať",
    licensePlate: "Registračná značka",
    contactUs: "Kontaktovať podporu",
    vehicleNotFound: "Vozidlo nebolo nájdené",
    needHelpLink: "Potrebujem pomoc",
    partner: "Akú službu hľadáte",
    brand: "Štátna poznávacia značka",
    vehicleRegistrationNumber: "Registration plate",
    address: "Adresa",
    radius: "Vyhľadať v okruhu",
    radiusDescription: "Zvoľte si vzdialenosť od miesta, kde sa nachádzate.",
    myLocation: "Moja poloha",
    show: "Zobraziť",
    print: "Tlačiť",
    pleaseSelect: "Prosím vyberte",
    allBrands: "Všetky značky",
    placeholder: "Zadajte požadovanú PZ",
    titleResults: "Výsledky",
    services: "Služby",
    titleMap: "Mapa",
    filterSection: "Filter",
    filterTitle: "Ďalšie služby",
    filterDescription: "Vyberte si odpovedajúce služby k zobrazeniu",
    serviceType: "Typ služby",
    headerName: "Názov",
    headerAddress: "Adresa",
    headerDistance: "Vzdialenosť",
    headerContact: "Kontakty",
    additionalServices: "Ďalšie služby",
    contactGsm: "Telefón",
    contactEmail: "E-mail",
    noResults: "Tomuto nastaveniu nezodpovedá žiadna služba.",
    navigate: "Navigovať",
    search: "Vyhľadať",
    deleteFilter: "Vymazať filter",
    servicePlaces: "Zoznam servisných miest",
    servicePlacesDescription: "Celkom nájdených {0} servisných miest",
    otherServices: "Ďalšie služby",
    privacyStatement: "Ochrana osobných údajov",
    termsAndConditions: "Obchodné podmienky",
    dataProtection: "Ochrana dát",
    cookiePolicy: "Cookies",
    ayvensGlobal: "Ayvens Global",
    newsroom: "Novinky",
    investorRelations: "Info pre oznamovateľov",
    defaultSelectValue: "Prosím vyberte",
    moreInformations: "Viac informácií",
    changeVehicle: "Zmeniť vozidlo",
    legend: "Legenda",
    loading: "Načítanie",
    selectionError: "Prosím vyberte službu",
    aircondition: "Klimatizácia",
    wash: "Mytie",
    pickup: "Vyzdvihnutie",
    others: "Ostatné",
    otherCar: "Náhradné vozidlo",
    websSite: "Www",
    openingHours: "Otváracia doba",
  },
  en: {
    welcomeHomePage: "Welcome to the Services app",
    welcomeDescription:
      "Log in by entering the registration plate of a valid contract.",
    vehicleLicensePlateErrorMessage:
      "Must be entered without spaces and characters",
    vehicleLicenseNotFound: "The entered license plate was not found",
    needHelpLink: "Need help",
    mainDialogFooterTitle: "Something doesn't work for you?",
    mainDialogFooterDescription:
      "Contact our customer support and we will solve everything.",
    continue: "Continue",
    licensePlate: "Registration plate",
    contactUs: "Contact support",
    vehicleNotFound: "Vehicle not found",
    partner: "Select service",
    brand: "Car manufacturer",
    vehicleRegistrationNumber: "Registration plate",
    address: "Address",
    radius: "Search in radius",
    radiusDescription: "Choose the distance from your location.",
    myLocation: "My location",
    show: "Show",
    print: "Print",
    pleaseSelect: "Please select",
    allBrands: "All brands",
    placeholder: "Enter registration plate",
    titleResults: "Results",
    titleMap: "Map",
    services: "Services",
    serviceType: "Service type",
    filterSection: "Filter",
    filterTitle: "Additional services",
    filterDescription: "Select the appropriate services to display",
    headerName: "Name",
    headerAddress: "Address",
    headerDistance: "Distance",
    headerContact: "Contacts",
    additionalServices: "Additional services",
    contactGsm: "Phone",
    contactEmail: "E-mail",
    noResults: "There is no service available.",
    navigate: "Navigate",
    search: "Search",
    deleteFilter: "Delete filter",
    servicePlaces: "Service places",
    servicePlacesDescription: "Total of {0} service places found",
    otherServices: "Other services",
    privacyStatement: "Privacy statement",
    termsAndConditions: "Terms and conditions",
    dataProtection: "Data protection",
    cookiePolicy: "Cookies",
    ayvensGlobal: "Ayvens Global",
    newsroom: "Newsroom",
    investorRelations: "Info for reporters",
    defaultSelectValue: "Please select",
    moreInformations: "More informations",
    changeVehicle: "Change vehicle",
    legend: "Legend",
    loading: "Loading",
    selectionError: "Please select service",
    aircondition: "Aircondition",
    wash: "Wash",
    pickup: "Pickup",
    others: "Others",
    otherCar: "Other car",
    websSite: "Www",
    openingHours: "Opening hours",
  },
});
