import React from "react";

import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import MapInfoWindow from "./MapInfoWindow";
import MapMarker from "../../../models/MapMarker";
import useStore from "../../../hooks-store/store";
import { Partner } from "../../../api/Client";
//import MapLegend, { MapLegendItem } from "./MapLegend";
import MarkerIcon from "../../../assets/img/marker_icon.svg";
import AppContext from "../../../context/AppContext";
import { Position } from "google-map-react";

interface MapProps {
  items?: MapMarker[];
  width?: number;
  height?: number;
}

const center = { lat: 50.0762369, lng: 14.4502317 };

const Map: FunctionComponent<MapProps> = ({
  items,
  width,
  height,
}: MapProps) => {
  const [partnerStore, setPartnerStore] = useStore<Partner | null>("partner");
  const [positionStore, setPositionStore] = useStore<Position>("position");
  const [isError, setIsError] = useState<boolean>(false);

  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(13);
  const appCtx = useContext(AppContext) as any;
  const [map, setMap] = useState<any>(null);

  const containerStyle = {
    width: width ? width?.toString() + "px" : "100%",
    height: height ? height.toString() + "px" : "100%",
    // borderRadius: "0.75rem",
  };

  const [activeMarker, setActiveMarker] = useState<number | undefined>(
    undefined
  );

  const handleActiveMarker = useCallback(
    (event: google.maps.MapMouseEvent, markerId: number | undefined) => {
      if (markerId === activeMarker) {
        setActiveMarker(undefined);
        return;
      }
      setActiveMarker(markerId);

      setInfoOpen(true);

      if (zoom < 13) {
        setZoom(13);
      }

      appCtx?.setSelectedPartnerId(markerId!);

      console.log("MapInfoWindow", markerId);
    },
    [activeMarker, infoOpen, zoom]
  );

  useEffect(() => {
    if (partnerStore) {
      setActiveMarker(partnerStore.id);
      setInfoOpen(true);
    }
  }, [partnerStore]);

  const fitBounds = useCallback(() => {
    if (items && items.length > 0) {
      const bounds = new google.maps.LatLngBounds(positionStore);
      items?.forEach((item) =>
        bounds.extend({
          lat: item.partnerInfo?.latitude ?? positionStore.lat,
          lng: item.partnerInfo?.longitude ?? positionStore.lng,
        } as any)
      );

      //it fails when my location is set
      try {
        map?.fitBounds(bounds);
      } catch (e) {
        setIsError(true);
        console.log(e);
      }
    }

    // map?.controls[google.maps.ControlPosition.TOP_RIGHT].push(
    //   document.getElementById("mapLegend")!
    // );
  }, [map, items, positionStore]);

  const handleOnLoad = useCallback((map: any) => {
    setMap(map);
  }, []);

  useEffect(() => {
    fitBounds();
  }, [items, fitBounds]);

  // const onUnmount = React.useCallback(function callback(map: any) {
  //   setMap(null);
  // }, []);

  // const legendData: Array<MapLegendItem> = [
  //   {
  //     icon: MarkerIcon,
  //     name: "Servisní místo",
  //     color: "#000000",
  //   },
  // ];

  const options = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    rotateControl: false,
  };

  return (
    <>
      {/* <MapLegend data={legendData} /> */}
      <GoogleMap
        key={isError ? "error" : "map"}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={options}
        //onUnmount={onUnmount}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(undefined)}
      >
        {items &&
          items.length > 0 &&
          items?.map((item) => (
            <MarkerF
              key={item.partnerInfo.id}
              //icon={StyledSVG as any}
              icon={{
                url: MarkerIcon,
                // fillColor: "red",
                // scale: 7,
              }}
              position={
                {
                  lat: item.partnerInfo?.latitude,
                  lng: item.partnerInfo?.longitude,
                } as any
              }
              onClick={(event: google.maps.MapMouseEvent) =>
                handleActiveMarker(event, item?.partnerInfo?.id ?? 0)
              }
            >
              {activeMarker === item?.partnerInfo?.id &&
                infoOpen &&
                item.partnerInfo?.latitude &&
                item.partnerInfo?.longitude && (
                  <InfoWindowF
                    key={"InfoWindowF_" + item?.partnerInfo?.id}
                    position={
                      {
                        lat: item.partnerInfo?.latitude,
                        lng: item.partnerInfo?.longitude,
                      } as any
                    }
                    onCloseClick={() => {
                      setInfoOpen(false);
                    }}
                  >
                    {infoOpen && (
                      <MapInfoWindow
                        key={"InfoWindow_" + item?.partnerInfo?.id}
                        marker={item}
                      />
                    )}
                  </InfoWindowF>
                )}
            </MarkerF>
          ))}
      </GoogleMap>
    </>
  );
};

export default memo(Map);
